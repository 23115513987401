export interface Position {
  uid: string;
  internalReference: string;
  title?: string;
  description?: string;
  contractType?: ContractType;
  occupationType?: OccupationType;
  practiceUid: string;
  requestUids?: string[];
  vetAnimalTypes?: VetAnimalType[];
  workConditionTypes?: WorkConditionsType[];
  perkTypes?: PerkType[];
  vetSkillTypes?: VetSkillType[];
  currency?: CurrencyType;
  minAnnualSalary?: number;
  maxAnnualSalary?: number;
  minDailySalary?: number;
  maxDailySalary?: number;
  isLive?: boolean;
  typicalWorkDay?: string;
  weeksAnnualLeave?: number;
  otherPerks?: string;
  isApproved?: boolean;
  distanceToPractice?: number;
}

export enum OccupationType {
  veterinarian = 'veterinarian'
}

export enum BadgeType {
  verified = 'verified'
}

export enum ContractType {
  fulltime = 'Full-time',
  parttime = 'Part-time',
  fixedterm = 'Fixed-term contract',
  locum = 'Locum'
}

export enum VetAnimalType {
  smallAnimal = 'Small Animal',
  farmAnimal = 'Farm Animal',
  equine = 'Equine',
  mixed = 'Mixed',
  exotics = 'Exotics/Wildlife/Zoo/Avian'
}

export enum WorkConditionsType {
  condition1 = 'Night vet position, working nights only',
  condition2 = 'Will involve sole charge',
  condition3 = 'Will involve managing a team'
}

export enum VetSkillType {
  skill1 = 'General Consultation and Examinations',
  skill2 = 'Out of hours emergencies',
  skill3 = 'Medicine work-up',
  skill4 = 'Routine Soft Tissue Surgery',
  skill5 = 'Routine Orthopaedic Surgery',
  skill6 = 'Dentistry',
  skill7 = 'Anaesthesia',
  skill8 = 'Routine Radiology',
  skill9 = 'Routine Ultra Sound',
  skill10 = 'Herd Health',
  skill11 = 'Fertility Work',
  skill12 = 'Artificial Insemination',
  skill13 = 'Caesareans',
  skill14 = 'TB Testing',
  skill15 = 'Pre-purchase investigation',
  skill16 = 'Stud work'
}

export enum CurrencyType {
  usd = 'USD',
  gbp = 'GBP',
  eur = 'EUR',
  zar = 'ZAR',
  aud = 'AUD',
  nzd = 'NZD'
}

export enum PerkType {
  perk1 = 'Flexible work hours',
  perk2 = 'No work on Weekends',
  perk3 = 'No work on Public holidays',
  perk4 = 'No work after hours',
  perk5 = 'Relocation allowance',
  perk6 = 'Professional membership fees',
  perk7 = 'Professional Insurance',
  perk8 = 'Private Health Insurance',
  perk9 = 'Bonus Scheme or Profit Share',
  perk10 = 'Potential for Partnership/Ownership'
}
