import { GeoPoint } from '@firebase/firestore-types';
import {
  ContractType,
  PerkType,
  WorkConditionsType,
  VetSkillType
} from './position.model';

export interface User {
  uid: string;
  name: string;
  surname: string;
  description: string;
  email: string;
  mobileNumber: string;
  occupation: OccupationType;
  address: string;
  city: string;
  country: string;
  googlePlaceId: string;
  geoPoint: GeoPoint;
  academicInstitution: string;
  yearOfAcademicQualification: number;
  acreditationInstitution: string;
  yearOfAccreditation: number;
  practiceUids: string[];
  requestUids: string[];
  vetAnimalTypes: VetAnimalType[];
  workConditionTypes: WorkConditionsType[];
  vetSkillTypes: VetSkillType[];
  badges: BadgeType[];
  currency: CurrencyType;
  minAnnualSalary: number;
  minDailySalary: number;
  profileImageRef?: string;
  cvFileRef?: string;
  preferredContractTypes?: ContractType[];
  preferredPerkTypes?: PerkType[];
  minWeeksAnnualLeave?: number;
  isVisible?: boolean;
  excludePractices?: string[];
  isCandidate?: boolean;
  isAdmin?: boolean;
  activePractice?: { name: string; uid: string };
  isApproved?: boolean;
}

export enum OccupationType {
  veterinarian = 'veterinarian'
}

export enum BadgeType {
  verified = 'verified'
}

export enum VetAnimalType {
  smallAnimal = 'Small Animal',
  farmAnimal = 'Farm Animal',
  equine = 'Equine',
  mixed = 'Mixed',
  exotics = 'Exotics/Wildlife/Zoo/Avian'
}

export enum CurrencyType {
  usd = 'USD',
  gbp = 'GBP',
  eur = 'EUR',
  zar = 'ZAR',
  aud = 'AUD',
  nzd = 'NZD'
}
